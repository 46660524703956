import React, { useState } from "react";
import { onBlurListener } from "../../pages/cart/validators";
import CardForm from "../CardForm/CardForm";

import "./PaymentInfo.scss";
import InputField from "../InputField";

const PaymentInfo = (props) => {
  const [nameOnCard, setNameOnCard] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("credit-card");

  return (
    <div className="billing-info-container gap-top-4">
      <h3 className="sub-header">So zahl’ ich:</h3>
      <form className="cart-payment-form mt-30" onBlur={onBlurListener}>
        <div className="horizontal-divider mt-25" />
        <div className="flex-edge gap mt-25">
          <div className="checkbox-container">
            <input
              className="cc-checkbox radio-input"
              type="radio"
              id="credit-card"
              value="credit-card"
              name="method"
              checked={paymentMethod === "credit-card"}
              onClick={(e) => setPaymentMethod(e.currentTarget.value)}
            />
            <label className="small-header" htmlFor="credit-card">
              Kredit- oder Debitkarte
            </label>
          </div>
          <div>
            <div className="inline">
              <img src="/icons/visa-icon.svg" alt="visa" />
              <img
                className="gap-left-1"
                src="/icons/mastercard-icon.svg"
                alt="visa"
              />
            </div>
          </div>
        </div>
        <div className="gap-top-6 form-full-width">
          <InputField
            // className="StripeElement StripeElement--empty"
            name="Name auf der Karte"
            id="NameaufderKarte"
            set={setNameOnCard}
            // placeholder="4242 4242 4242 4242"
            required={true}
          />
        </div>
      </form>
      <div className="gap-top-6 form-full-width">
        <CardForm />
      </div>
      <p className="caption-text grey-text mt-30">
        Deine Transaktion ist mit SSL-Verschlüsselung gesichert
      </p>
    </div>
  );
};

export default PaymentInfo;
