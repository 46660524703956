import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";

export const RestrictedRoute = (props) => {
  console.log("restricted route");
  const authCtx = useAuthContext();
  if (authCtx.isLoading) {
    return <div> Loading...</div>;
  }

  if (authCtx.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};
