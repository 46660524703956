import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";

export const PrivateRoute = (props) => {
  const pathName = props?.location?.pathname;
  const authCtx = useAuthContext();

  if (authCtx.isLoading) {
    return <div> Loading...</div>;
  }

  if (pathName) {
    localStorage.setItem("route_from", pathName);
  }

  // @TODO commented for testing redirects
  // if (props?.location?.pathname?.startsWith("/order-success")) {
  //   localStorage.setItem("route_from", props.location.pathname);
  // }

  if (!authCtx.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
