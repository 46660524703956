import React from "react";

import Flickity from "react-flickity-component";
import "flickity/dist/flickity.min.css";
import "flickity-as-nav-for";

import { useResponsiveDimensions } from "../../../lib/useResponsiveDimensions";

import "./ProductCarousel.scss";

const ProductCarousel = ({ data }) => {
  const isMobile = useResponsiveDimensions().isMobile;
  return (
    <div className="product-carousel-container">
      <Flickity
        options={{
          prevNextButtons: isMobile ? false : true,
          pageDots: false,
          wrapAround: true,
        }}
        className="carousel-main"
      >
        {data.images.map((image) => (
          <div className="carousel-product">
            <img className="product-image" src={image.src} />
          </div>
        ))}
      </Flickity>
      <div className="product-thumb-container">
        <Flickity
          className="carousel-nav"
          options={{
            prevNextButtons: false,

            asNavFor: ".carousel-main",
            contain: true,
            pageDots: false,
          }}
        >
          {data.images.map((image) => (
            <div className="carousel-product-thumb">
              <img className="product-thumb-image" src={image.src} />
            </div>
          ))}
        </Flickity>
      </div>
    </div>
  );
};

export default ProductCarousel;
