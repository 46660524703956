import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../contexts/StoreContext";
import { UserContext } from "../../contexts/UserContext";

const UserDashboardProducts = (props) => {
  const { products } = useContext(StoreContext);
  const [editProducts, setEditProducts] = useState(false);
  const { removeProduct, selectedProducts, userCompletedTasks } =
    useContext(UserContext);
  const handleEditProducts = (product) => {
    removeProduct(product);
  };
  return (
    <div className="ud-select-products-container mt-30" id="ud-products">
      <div className="flex-edge mt-30">
        <div>
          <p className="sub-header">1. Lieblingsprodukt aussuchen</p>
        </div>
        <div className="completed-chip chip-flex align-items-center bw-229">
          <p
            className={
              props.selectedProducts.length
                ? "green-text price-text bold"
                : "grey-text price-text bold"
            }
          >
            {props.selectedProducts.length ? "Komplett" : "Unvollständig"}
          </p>
          <div
            className={
              props.selectedProducts.length
                ? "completed-icon-container-green"
                : "completed-icon-container-grey"
            }
          >
            <img className="center-hv" src="/icons/tick-icon.svg" alt="tick" />
          </div>
        </div>
      </div>

      <div
        className={
          userCompletedTasks.length > 0
            ? "userdashboard_product-column"
            : "userdashboard_product"
        }
      >
        {props.selectedProducts.map((product) => {
          return (
            <div
              className={window.innerWidth > 576 ? "" : "mt-12"}
              key={product.id}
            >
              {window.innerWidth > 576 && (
                <div className="ud-product-overview-container" key={product.id}>
                  {editProducts ? (
                    <span
                      className="userdashboard_garbage-can"
                      onClick={() => handleEditProducts(product)}
                    >
                      <img
                        src="/icons/garbage_can.svg"
                        alt="garbage can icon"
                      />
                    </span>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p className="small-header">
                      {
                        products.find((e) =>
                          e.variants.some((n) => n.id === product.id)
                        )["title"]
                      }
                    </p>
                    <p className="small-header">{product.title}</p>
                    <div className="horizontal-divider mt-11"></div>
                    <div className="flex-edge mt-9">
                      <p className="grey-text caption-text">Preis:</p>
                      <p className="grey-text caption-text">
                        <span className="chf">CHF </span>
                        {product.price}
                      </p>
                    </div>
                    <div className="flex-edge">
                      <p className="caption-text">Stabil:</p>
                      <p className="highlight small-header">
                        Wähle deinen Deal!
                        {/*{Math.floor(product.price / 2)}.00*/}
                      </p>
                    </div>
                  </div>
                  <img src={product.image.src} alt="product" />
                </div>
              )}
              {window.innerWidth <= 576 && (
                <div className="card-1">
                  <div
                    className="col-grid-3"
                    style={{ gridTemplateColumns: "1fr" }}
                  >
                    <img src={product.image.src} alt="product" />
                  </div>
                  <p className="small-header mt-11">{product.name}</p>
                  <div className="horizontal-divider mt-13" />
                  <div className="flex-edge mt-9">
                    <p className="grey-text caption-text">Preis:</p>
                    <p className="grey-text caption-text">
                      <span className="chf">CHF </span>
                      {product.price}
                    </p>
                  </div>
                  <div className="flex-edge">
                    <p className="caption-text">Stabil:</p>
                    <p className="highlight small-header">
                      <span className="chf">CHF </span>
                      {Math.floor(product.price / 2)}.00
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="userdashboard_product-add">
          <Link to="/products">
            <div className="userdashboard_product-add-inner">
              <img src="/icons/plus.svg" alt="add product icon" />
              <p className="grey-text">Produkt hinzugügen</p>
            </div>
          </Link>
        </div>
      </div>
      {window.innerWidth > 576 && (
        <div>
          <div className="horizontal-divider mt-28" />
          <div className="flex-edge flo mt-50 align-items-center">
            {editProducts ? (
              <div
                className="userdashboard_save-button bw-184"
                onClick={() => setEditProducts(false)}
              >
                Änderungen speichern
              </div>
            ) : (
              <div
                className="outline-button bw-184"
                onClick={() => setEditProducts(true)}
              >
                Produkte ändern
              </div>
            )}
            <p className="grey-text caption-text">
              ausgewählte Produkte: {selectedProducts.length}
            </p>
          </div>
          <div className="mt-30" />
        </div>
      )}
      {window.innerWidth <= 576 && (
        <div>
          <div className="horizontal-divider mt-28" />
          <p className="grey-text caption-text mt-15 text-center">
            ausgewählte Produkte: 1
          </p>
          <div className="flex-edge flo mt-15 align-items-center">
            <Link
              style={{ textDecoration: "none", width: "100%" }}
              to="/products"
              id="products-link"
            >
              <div className="outline-button fill-button">Produkte ändern</div>
            </Link>
          </div>
          <div className="mt-20" />
        </div>
      )}
    </div>
  );
};

export default UserDashboardProducts;
