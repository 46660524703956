import React, { useContext, useEffect, useState } from "react";
import Signup from "../../singin_signup/Signup";
import ProductCarousel from "../../../components/products/ProductCarousel/ProductCarousel";
import { useResponsiveDimensions } from "../../../lib/useResponsiveDimensions";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { UserContext } from "../../../contexts/UserContext";
import { StoreContext } from "../../../contexts/StoreContext";

import "./SingleProduct.scss";

const SingleProduct = () => {
  const AuthCtx = useAuthContext();
  const [textExpanded, setTextExpanded] = useState(false);
  const isMobile = useResponsiveDimensions().isMobile;
  const history = useHistory();
  const [activeProduct, setActiveProduct] = useState({});
  const id = history.location.pathname.replace("/product/", "");

  const [productImages, setProductImages] = useState(
    activeProduct ? activeProduct.images : []
  );

  const productVariant = activeProduct.handle ? activeProduct?.variants[0] : {};

  // const [productVariant, setProductVariant] = useState(
  //   Object.keys(activeProduct).length > 0
  //     ? activeProduct.variants[0]
  //     : { price: "1000" }
  // );
  const { selectedProducts, updateProducts } = useContext(UserContext);
  const { fetchAllProducts, products, addItemToCheckout } =
    useContext(StoreContext);

  const getProduct = (id) => {
    const product = products.find((item) => item.id === id);

    setActiveProduct(product);
  };

  useEffect(() => {
    getProduct(id);
  }, [id]);

  let options =
    Object.keys(activeProduct).length > 0
      ? activeProduct.variants[0].selectedOptions
      : [];
  let optionProperty =
    Object.keys(activeProduct).length > 0
      ? Object.fromEntries(options.map((option) => [option.name, option.value]))
      : {};
  const [selectedOptions, setSelectedOptions] = useState(optionProperty);
  const [openSignUp, setOpenSignUp] = useState(false);
  useEffect(() => {
    fetchAllProducts();
    return () => {};
  }, [fetchAllProducts, productImages]);

  useEffect(() => {
    if (Object.keys(activeProduct).length > 0) {
      let productOptions = activeProduct.variants[0].selectedOptions;
      let objectState = productOptions.reduce((acc, e) => {
        acc = { ...acc, [e.name]: e.value };
        return acc;
      }, {});
      setSelectedOptions(objectState);
      activeProduct.variants[0].selectedOptions.forEach((option) => {
        let inputElement = document.getElementById(`${option.value}`);
        // inputElement.classList.add("options-item-active");
      });
      Object.entries(selectedOptions).forEach((n) => {
        let firstPropertyName = n[0];
        let firstPropertyValue = n[1];
        let otherPropertyNames = Object.keys(selectedOptions).filter(
          (e) => e !== firstPropertyName
        );
        otherPropertyNames.forEach((propertyName) => {
          let labels = document.querySelectorAll(`#${propertyName} label`);
          labels.forEach((label) => {
            let variant = findVarinat(
              firstPropertyName,
              firstPropertyValue,
              propertyName,
              label
            );
            if (!variant) {
              label.classList.add("unavilable");
            }
          });
        });
      });
    }
  }, []);

  const findVarinat = (firstKey, firstValue, propertyName, label) => {
    let variants = activeProduct.variants;
    let variant = variants.find((e) => {
      let selectedOptions = e.selectedOptions;
      let names = selectedOptions.map((n) => n.name);
      let values = selectedOptions.map((n) => n.value);

      return (
        names.includes(firstKey) &&
        names.includes(propertyName) &&
        values.includes(firstValue) &&
        values.includes(label.id)
      );
    });
    return variant;
  };

  useEffect(() => {
    if (Object.keys(activeProduct).length > 0) {
      let variants = activeProduct.variants;
      let variant = variants.find((e) => {
        let variantOption = e.selectedOptions;
        return variantOption.every(
          (option) =>
            Object.keys(selectedOptions).includes(option.name) &&
            Object.values(selectedOptions).includes(option.value)
        );
      });
      // setProductVariant(variant);
    }
  }, [selectedOptions]);
  const fillImages = () => {
    const length = activeProduct.images.length;
    const newList = Object.assign([], activeProduct.images);
    let counter = 0;
    while (newList.length < 6) {
      newList.push(activeProduct.images[counter]);
      if (counter === length - 1) {
        counter = 0;
      } else {
        counter = counter + 1;
      }
    }
    setProductImages(newList);
  };

  const rotateArray = (direction) => {
    const newList = [];
    if (direction === "next") {
      for (let i = 1; i < productImages.length; i++) {
        newList.push(productImages[i]);
      }
      newList.push(productImages[0]);
    } else {
      newList.push(productImages[productImages.length - 1]);
      for (let i = 0; i < productImages.length - 1; i++) {
        newList.push(productImages[i]);
      }
    }
    setProductImages(newList);
  };

  const expandText = () => {
    const elements = document.getElementsByClassName("product-desc");
    for (let i = 0; i < elements.length; i++) {
      if (textExpanded) {
        elements.item(i).style.overflow = "hidden";
        elements.item(i).style.height = "25.25px";
        document.getElementById("expand-p").innerHTML = "Show more...";
        setTextExpanded(false);
      } else {
        elements.item(i).style.overflow = "none";
        elements.item(i).style.height = "auto";
        document.getElementById("expand-p").innerHTML = "Show less...";
        setTextExpanded(true);
      }
    }
  };

  const handleOpenSignUp = () => {
    history.push("/login");
  };
  const handleOptionsSelection = (key, value, e) => {
    let variants = activeProduct.variants;
    let otherProperties = Object.keys(selectedOptions).filter((e) => e !== key);
    let propertyToInputsMapping = otherProperties.map((property) => ({
      [property]: Array.from(document.querySelectorAll(`#${property} input`)),
    }));
    propertyToInputsMapping.forEach((mapping) => {
      const property = Object.keys(mapping)[0];
      const inputs = Object.values(mapping)[0];
      inputs.forEach((input) => {
        let variant = variants.find((e) => {
          let otherSelectedOptions = e.selectedOptions;
          let names = otherSelectedOptions.map((n) => n.name);
          let values = otherSelectedOptions.map((n) => n.value);
          return (
            names.includes(key) &&
            values.includes(value) &&
            names.includes(property) &&
            values.includes(input.value)
          );
        });
        if (input.parentElement.classList.contains("unavilable")) {
          input.parentElement.classList.remove("unavilable");
        }
        if (!variant) {
          input.parentElement.classList.add("unavilable");
        }
      });
    });
    setSelectedOptions((state) => ({ ...state, [key]: value }));
    let parentElement = e.target.parentElement;
    let childrenElements = Array.from(parentElement.children);
    childrenElements.forEach((e) => {
      e.classList.remove("options-item-active");
    });
    e.target.classList.add("options-item-active");
  };

  const addToCart = () => {
    updateProducts(activeProduct?.variants[0]);
    addItemToCheckout(activeProduct?.variants[0].id, 1);
  };

  // if (productImages.length < 6 && activeProduct.title) {
  //   fillImages();
  // }

  if (!activeProduct?.handle) {
    return null;
  }

  const isProductSelected = selectedProducts.some(
    (e) => e.id === activeProduct?.variants[0].id
  );

  return (
    <div
      className="single-product-page active-product-panel"
      id="active-product-panel"
    >
      {!isMobile && (
        <>
          <div className="col-grid-2">
            {openSignUp ? (
              <Signup openSignUp={openSignUp} setOpenSignUp={setOpenSignUp} />
            ) : null}
            <ProductCarousel data={activeProduct} />
            <div className="image-frame">
              <p className="mt-26 title">{activeProduct.title}</p>
              <p className="grey-text mt-21 price-text">
                die geilsten Features
              </p>
              {JSON.parse(activeProduct?.description).features.map(
                (feature) => {
                  return (
                    <p key={feature} className="mt-9 body-text">
                      <span className="highlight bold">• </span>&nbsp;
                      {feature}
                    </p>
                  );
                }
              )}
              <p className="grey-text mt-23 price-text">Das Produkt erklärt:</p>
              <p className="body-text mt-8">
                {JSON.parse(activeProduct.description).description}
              </p>
              <div className="expand-container mt-6" onClick={expandText}>
                <p className="body-text hyperlink" id="expand-p">
                  Zeig’ mehr...
                </p>
              </div>
              {activeProduct.options.map((option, i) => {
                return (
                  <React.Fragment key={i}>
                    <p className="grey-text mt-23 price-text">{option.name}</p>
                    <div className="options-container" id={option.name}>
                      {option.values.map((value, i) => (
                        <label
                          key={i}
                          id={value.value}
                          className="options-label"
                          htmlFor={value.value}
                          onClick={(e) =>
                            handleOptionsSelection(option.name, value.value, e)
                          }
                        >
                          {value.value}
                          <input
                            className="options-hidden"
                            value={value.value}
                            name={value.value}
                          />
                        </label>
                      ))}
                    </div>
                  </React.Fragment>
                );
              })}

              <div className="product-selection-bar">
                <div className="product-selection-bar-content">
                  <div className="product-selection-bar-prices">
                    <p className="grey-text body-text strike">
                      CHF{productVariant?.price}
                    </p>
                    <p className="highlight small-header">Wähle deinen Deal!</p>
                  </div>
                  {!AuthCtx.isAuthenticated ? (
                    <div
                      onClick={handleOpenSignUp}
                      className="cta-button blue bw-135 cta-text nounderline"
                    >
                      Ja, ich will!
                    </div>
                  ) : (
                    <div
                      // disabled={isProductSelected}
                      className={
                        isProductSelected
                          ? "cta-button green bw-135 cta-text mt-20"
                          : "cta-button blue bw-135 cta-text mt-20"
                      }
                      id={activeProduct.title}
                      onClick={() => {
                        addToCart();
                        // history.push("/partners");
                      }}
                    >
                      {isProductSelected ? "erneut wählen" : "Ja, ich will!"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isMobile && (
        <div className="col-grid-1 mt-14">
          <ProductCarousel data={activeProduct} />
        </div>
      )}

      {isMobile && (
        <div className="product-selection-bar">
          <div className="gradient-separator" />
          <div className="big-bottom-info">
            <div className="inline justify-center force-column mt-20">
              <p className="grey-text highlight-text strike">
                CHF{activeProduct.variants[0].price}
              </p>
              <p className="highlight sub-header">Wähle deinen Deal!</p>
            </div>
            <div className="horizontal-divider mt-13" />
            <div
              className={
                selectedProducts.includes(activeProduct)
                  ? "cta-button green fill-button b-mobile-height body-text mt-20"
                  : "cta-button blue fill-button b-mobile-height body-text mt-20"
              }
              id={activeProduct.name}
              onClick={() => addToCart()}
            >
              {selectedProducts.includes(activeProduct)
                ? "Product selected"
                : "Ja, ich will!"}
            </div>
            <div className="mt-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProduct;
