import React, { Component } from "react";
import dbClient from "../utils/dbClient";
const UserContext = React.createContext();

class UserProvider extends Component {
  localStorageCart = JSON.parse(localStorage.getItem("cart"));

  state = {
    user: {},
    activeProduct: {},
    selectedProducts: this.localStorageCart ? this.localStorageCart : [],
    selectedPartners: [],
    userCompletedTasks: [],
    userPendingTasks: [],
  };

  deleteUserPendingTask = async (taskId) => {
    try {
      const response = await dbClient.delete("/user/deletePendingTask", {
        data: { taskId },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.user.jwtToken,
        },
      });
    } catch (error) {
      console.log("Error deleting user pending task", error);
    }
  };

  createUserPendingTask = async (taskId) => {
    try {
      const response = await dbClient.post(
        "/user/createPendingTask",
        { taskId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.state.user.jwtToken,
          },
        }
      );
    } catch (error) {
      console.log("Error creating user pending task", error);
    }
  };

  setUserPendingTasks = async () => {
    try {
      const response = await dbClient.get("/user/getPendingTasks", {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.user.jwtToken,
        },
      });
      this.setState({ userPendingTasks: response.data });
    } catch (error) {
      console.log("Error retrieving Pending tasks.", error);
    }
  };

  getUserPendingTasks = async () => {
    try {
      const response = await dbClient.get("/user/getPendingTasks", {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.user.jwtToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log("Error retrieving Pending tasks.", error);
    }
  };

  signIn = async (userInfo) => {
    this.setState({ user: userInfo });
    try {
      const response = await dbClient.get("/user/completedTasks", {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.user.jwtToken,
        },
      });
      this.setState({ userCompletedTasks: response.data.tasks });
    } catch (error) {}
  };

  updateUserCompletedTasks = async () => {
    try {
      const response = await dbClient.get("/user/completedTasks", {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.user.jwtToken,
        },
      });
      this.setState({ userCompletedTasks: response.data.tasks });
    } catch (error) {}
  };

  signOut = () => {
    this.setState({});
  };
  setActiveProduct = (product) => {
    this.setState({ activeProduct: product });
  };

  updateProducts = (product) => {
    let productList = [...this.state.selectedProducts];
    productList.push(product);
    this.setState({ selectedProducts: productList });
    localStorage.setItem("cart", JSON.stringify(productList));
  };

  removeProduct = (product) => {
    let productList = [...this.state.selectedProducts];
    productList = productList.filter((e) => e.id !== product.id);
    this.setState({ selectedProducts: productList });
    localStorage.setItem("cart", JSON.stringify(productList));
  };

  resetProducts = () => {
    this.setState({ selectedProducts: [] });
    localStorage.setItem("cart", JSON.stringify([]));
  };

  updatePartners = async (partner) => {
    let partnerList = Object.assign([], this.state.selectedPartners);
    if (this.state.selectedPartners.find((e) => e.name === partner.name)) {
      partnerList = partnerList.filter((e) => e.name !== partner.name);
    } else {
      partnerList.push(partner);
    }
    this.setState({ selectedPartners: partnerList });
  };
  completedTask = async (partner) => {
    const resp = await dbClient.post(
      "/user/completedTasks",
      {
        taskId: partner.task.id,
        credits: partner.task.credits,
        action: "add",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.user.jwtToken,
        },
      }
    );
    this.state.user.credits = resp.data.credits;
  };

  render() {
    console.log("active product", this.state.activeProduct);
    return (
      <UserContext.Provider
        value={{
          user: this.state.user,
          activeProduct: this.state.activeProduct,
          signIn: this.signIn,
          signOut: this.signOut,
          setActiveProduct: this.setActiveProduct,
          selectedProducts: this.state.selectedProducts,
          updateProducts: this.updateProducts,
          removeProduct: this.removeProduct,
          resetProducts: this.resetProducts,
          completedTask: this.completedTask,
          userCompletedTasks: this.state.userCompletedTasks,
          updateUserCompletedTasks: this.updateUserCompletedTasks,
          selectedPartners: this.state.selectedPartners,
          updatePartners: this.updatePartners,
          createUserPendingTask: this.createUserPendingTask,
          deleteUserPendingTask: this.deleteUserPendingTask,
          getUserPendingTasks: this.getUserPendingTasks,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const UserConsumer = UserContext.Consumer;

export { UserConsumer, UserContext };

export default UserProvider;
