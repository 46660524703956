import React from "react";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const UserDashboardPartners = (props) => {
	const {
		updatePartners,
		userCompletedTasks,
		createUserPendingTask,
		getUserPendingTasks,
		deleteUserPendingTask,
	} = useContext(UserContext);
	const [eidtPartners, setEditPartners] = useState(false);
	const handlePartnerTask = async (partner) => {
		await createUserPendingTask(partner.task.id);
	};
	const deletePartner = async (partner, e) => {
		e.stopPropagation();
		let tasks = await getUserPendingTasks();
		if (tasks.find((e) => e.taskId === partner.task.id))
			await deleteUserPendingTask(partner.task.id);
		updatePartners(partner);
	};

	return (
		<div className="ud-select-products-container mt-30" id="ud-partners">
			<div className="flex-edge mt-30">
				<div>
					<p className="sub-header">2. Gönner wählen</p>
				</div>
				<div className="completed-chip chip-flex align-items-center bw-229">
					<p
						className={
							props.partnersComplete
								? "green-text price-text bold"
								: "grey-text price-text bold"
						}
					>
						{props.partnersComplete ? "Komplett" : "Unvollständig"}
					</p>
					<div
						className={
							props.partnersComplete
								? "completed-icon-container-green"
								: "completed-icon-container-grey"
						}
					>
						<img
							className="center-hv"
							src={
								props.partnersComplete
									? "/icons/tick-icon.svg"
									: "/icons/cross-icon.svg"
							}
							alt="cross"
						></img>
					</div>
				</div>
			</div>
			<div className="card-grid-3 mt-50">
				{props.selectedPartners.map((partner) => {
					return (
						<div
							className="card-1"
							key={partner.id}
							style={{ position: "relative" }}
							onClick={() => handlePartnerTask(partner)}
						>
							<a href={partner.task.taskUrl} target="_blank" rel="noreferrer">
								<div className="partner_status">
									<img src={partner.logo} alt="partner"></img>
									<div className="bottom-info">
										<p className="grey-text caption-text">
											Credits:{" "}
											<span className="highlight">{partner.task.credits}</span>
										</p>
									</div>
									{eidtPartners ? (
										<div
											className="userdashboard_partner-garbage-can"
											onClick={(e) => deletePartner(partner, e)}
										>
											<img src="/icons/delete_mark.svg" alt="tick"></img>
										</div>
									) : (
										<div className="userdashboard_partner-pending">
											<img src="/icons/pending_task.svg" alt="tick"></img>
										</div>
									)}
								</div>
							</a>
						</div>
					);
				})}
				{userCompletedTasks.map((task) => {
					return (
						<div
							className="card-1 card-1-completed"
							key={`$completed_task_${task.id}`}
							style={{ position: "relative" }}
						>
							<div className="partner_status">
								<img src={task.logo} alt="partner"></img>
								<div className="bottom-info">
									<p className="grey-text caption-text">
										Credits: <span className="highlight">{task.credits}</span>
									</p>
								</div>
								<div className="userdashboard_partner-pending">
									<img src="/images/select_mark.svg" alt="tick"></img>
								</div>
							</div>
						</div>
					);
				})}
				<div className="userdashboard_product-add">
					<Link to="/partners">
						<div className="userdashboard_product-add-inner">
							<img
								src="/icons/plus.svg"
								alt="add product icon"
								width="auto"
							></img>
							<p className="grey-text">Gönner hinzugügen</p>
						</div>
					</Link>
				</div>
			</div>
			<div className="horizontal-divider mt-28"></div>
			{window.innerWidth > 576 && (
				<div className="flex-edge flo mt-50">
					{!eidtPartners ? (
						<div
							className="outline-button bw-184 mt-15"
							onClick={() => setEditPartners(true)}
						>
							Gönner ändern
						</div>
					) : (
						<div
							className="userdashboard_save-button bw-184 mt-15"
							onClick={() => setEditPartners(false)}
						>
							Änderungen speichern
						</div>
					)}
					<div style={{ display: "inline-flex", alignItems: "center" }}>
						<p className="grey-text caption-text">
							ausgewählte Gönner: {props.selectedPartners.length}
						</p>
					</div>
				</div>
			)}
			{window.innerWidth <= 576 && (
				<div className="mt-15">
					<p className="grey-text caption-text text-center">
						ausgewählte Gönner: {props.selectedPartners.length}
					</p>

					{eidtPartners ? (
						<div
							className="outline-button fill-button mt-15"
							onClick={() => setEditPartners(true)}
						>
							Gönner ändern
						</div>
					) : (
						<div className="cta-button blue bw-214 gap-left-35">
							Save Changes
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default UserDashboardPartners;
