import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { StoreContext } from "../../../contexts/StoreContext";

import "./ProductsGrid.scss";

const ProductsGrid = (props) => {
  const [textExpanded, setTextExpanded] = useState(false);
  const history = useHistory();
  const { setActiveProduct } = useContext(UserContext);
  const [productImages, setProductImages] = useState(
    props.activeProduct ? props.activeProduct.images : []
  );
  const [productVariant, setProductVariant] = useState(
    Object.keys(props.expandedProduct).length > 0
      ? props.expandedProduct.variants[0]
      : { price: "1000" }
  );
  const { selectedProducts, updateProducts } = useContext(UserContext);
  const { fetchAllProducts, products, addItemToCheckout } =
    useContext(StoreContext);
  //continue from here and replace options

  let options =
    Object.keys(props.expandedProduct).length > 0
      ? props.expandedProduct.variants[0].selectedOptions
      : [];
  let optionProperty =
    Object.keys(props.expandedProduct).length > 0
      ? Object.fromEntries(options.map((option) => [option.name, option.value]))
      : {};
  const [selectedOptions, setSelectedOptions] = useState(optionProperty);
  const [openSignUp, setOpenSignUp] = useState(false);
  useEffect(() => {
    fetchAllProducts();
    return () => {};
  }, [fetchAllProducts, productImages]);

  useEffect(() => {
    if (Object.keys(props.expandedProduct).length > 0) {
      let productOptions = props.expandedProduct.variants[0].selectedOptions;
      let objectState = productOptions.reduce((acc, e) => {
        acc = { ...acc, [e.name]: e.value };
        return acc;
      }, {});
      setSelectedOptions(objectState);
      props.expandedProduct.variants[0].selectedOptions.forEach((option) => {
        let inputElement = document.getElementById(`${option.value}`);
        // inputElement.classList.add("options-item-active");
      });
      Object.entries(selectedOptions).forEach((n) => {
        let firstPropertyName = n[0];
        let firstPropertyValue = n[1];
        let otherPropertyNames = Object.keys(selectedOptions).filter(
          (e) => e !== firstPropertyName
        );
        otherPropertyNames.forEach((propertyName) => {
          let labels = document.querySelectorAll(`#${propertyName} label`);
          labels.forEach((label) => {
            let variant = findVarinat(
              firstPropertyName,
              firstPropertyValue,
              propertyName,
              label
            );
            if (!variant) {
              label.classList.add("unavilable");
            }
          });
        });
      });
    }
  }, []);

  const findVarinat = (firstKey, firstValue, propertyName, label) => {
    let variants = props.expandedProduct.variants;
    let variant = variants.find((e) => {
      let selectedOptions = e.selectedOptions;
      let names = selectedOptions.map((n) => n.name);
      let values = selectedOptions.map((n) => n.value);

      return (
        names.includes(firstKey) &&
        names.includes(propertyName) &&
        values.includes(firstValue) &&
        values.includes(label.id)
      );
    });
    return variant;
  };

  useEffect(() => {
    if (Object.keys(props.expandedProduct).length > 0) {
      let variants = props.expandedProduct.variants;
      let variant = variants.find((e) => {
        let variantOption = e.selectedOptions;
        return variantOption.every(
          (option) =>
            Object.keys(selectedOptions).includes(option.name) &&
            Object.values(selectedOptions).includes(option.value)
        );
      });
      setProductVariant(variant);
    }
  }, [selectedOptions]);
  const fillImages = () => {
    const length = props.expandedProduct.images.length;
    const newList = Object.assign([], props.expandedProduct.images);
    let counter = 0;
    while (newList.length < 6) {
      newList.push(props.expandedProduct.images[counter]);
      if (counter === length - 1) {
        counter = 0;
      } else {
        counter = counter + 1;
      }
    }
    setProductImages(newList);
  };

  const rotateArray = (direction) => {
    const newList = [];
    if (direction === "next") {
      for (let i = 1; i < productImages.length; i++) {
        newList.push(productImages[i]);
      }
      newList.push(productImages[0]);
    } else {
      newList.push(productImages[productImages.length - 1]);
      for (let i = 0; i < productImages.length - 1; i++) {
        newList.push(productImages[i]);
      }
    }
    setProductImages(newList);
  };

  const expandText = () => {
    const elements = document.getElementsByClassName("product-desc");
    for (let i = 0; i < elements.length; i++) {
      if (textExpanded) {
        elements.item(i).style.overflow = "hidden";
        elements.item(i).style.height = "25.25px";
        document.getElementById("expand-p").innerHTML = "Show more...";
        setTextExpanded(false);
      } else {
        elements.item(i).style.overflow = "none";
        elements.item(i).style.height = "auto";
        document.getElementById("expand-p").innerHTML = "Show less...";
        setTextExpanded(true);
      }
    }
  };

  const handleOpenSignUp = () => {
    history.push("/login");
  };
  const handleOptionsSelection = (key, value, e) => {
    let variants = props.expandedProduct.variants;
    let otherProperties = Object.keys(selectedOptions).filter((e) => e !== key);
    let propertyToInputsMapping = otherProperties.map((property) => ({
      [property]: Array.from(document.querySelectorAll(`#${property} input`)),
    }));
    propertyToInputsMapping.forEach((mapping) => {
      const property = Object.keys(mapping)[0];
      const inputs = Object.values(mapping)[0];
      inputs.forEach((input) => {
        let variant = variants.find((e) => {
          let otherSelectedOptions = e.selectedOptions;
          let names = otherSelectedOptions.map((n) => n.name);
          let values = otherSelectedOptions.map((n) => n.value);
          return (
            names.includes(key) &&
            values.includes(value) &&
            names.includes(property) &&
            values.includes(input.value)
          );
        });
        if (input.parentElement.classList.contains("unavilable")) {
          input.parentElement.classList.remove("unavilable");
        }
        if (!variant) {
          input.parentElement.classList.add("unavilable");
        }
      });
    });
    setSelectedOptions((state) => ({ ...state, [key]: value }));
    let parentElement = e.target.parentElement;
    let childrenElements = Array.from(parentElement.children);
    childrenElements.forEach((e) => {
      e.classList.remove("options-item-active");
    });
    e.target.classList.add("options-item-active");
  };

  const addToCart = () => {
    updateProducts(productVariant);
    addItemToCheckout(productVariant.id, 1);
  };
  if (productImages.length < 6 && props.expandedProduct.title) {
    fillImages();
  }
  const isProductSelected = selectedProducts.some(
    (e) => e.id === productVariant.id
  );

  return (
    <div className="nested-products-grid" id="nested-products-grid">
      {products.map((product) => {
        return (
          <div
            key={product.id}
            className="
              product-container card-1 transparent-fade nested-product-card padding-15 flex-edge-vertical"
          >
            <div className="product-header">
              <p className="body-text bold">{product.title}</p>
            </div>
            <div
              className="p-image"
              onClick={() => {
                setActiveProduct(product);
                history.push(`/product/${product.id}`);
              }}
            >
              {" "}
              <img
                className="yeet"
                src={product.images[0].src}
                alt="apple watch"
              />
            </div>
            <div>
              <div className="horizontal-divider mt-9" />
              <div className="flex-edge mt-9">
                <p className="grey-text price-text">Original Preis:</p>
                <p className="grey-text price-text">
                  CHF{product.variants[0].price}
                </p>
              </div>
              <div className="flex-edge">
                <p className="price-text bold">Dein Preis:</p>
                <p className="highlight price-text bold">Wähle deinen Deal!</p>
              </div>
              <div
                className="soft-blue-button fill-button mt-12"
                onClick={() => {
                  setActiveProduct(product);
                  history.push(`/product/${product.id}`);
                }}
                id={product.title}
              >
                mehr infos
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductsGrid;
