import React, { forwardRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useSignUpFormik } from "./useSignUpFormik";
import { CloseButton } from "../CloseButton";

import Agb from "../../assets/pdf/Agb.pdf";
import DatenSchutz from "../../assets/pdf/Datenschutz.pdf";

import "./SignUpForm.scss";

const Transition = forwardRef(function Transition(props, ref) {
  const { classes, ...other } = props;
  return <Slide direction="up" ref={ref} {...other} />;
});

const SignUpForm = (props) => {
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const AuthCtx = useAuthContext();

  const switchDialog = () => {
    props.handleClose();
    props.switchDialog();
  };

  const formik = useSignUpFormik({
    onSubmit: async (values) => {
      setIsLoading(true);
      if (checked) {
        AuthCtx.signUp(values).then(() => {
          setIsLoading(false);
        });
      } else {
        setCheckedError();
      }
    },
  });

  return (
    <Dialog
      className="signup-form-modal"
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
    >
      <div className="error-message text-center">{AuthCtx?.error}</div>
      <DialogTitle className="text-center">{"Sign up"}</DialogTitle>
      <CloseButton close={AuthCtx.closeSignUpModal} />
      <DialogContent>
        <form noValidate={true} onSubmit={formik.handleSubmit}>
          <input
            id="name"
            className="form-input"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            type="text"
          />
          {formik.errors.name && formik.touched.name && (
            <div className="required-message">{formik.errors.name}</div>
          )}
          <input
            className="form-input"
            id="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            type="email"
          />
          {formik.errors.email && formik.touched.email && (
            <div className="required-message">{formik.errors.email}</div>
          )}
          <input
            className="form-input"
            id="mobile"
            placeholder="Mobile Nummer"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mobile}
          />
          {formik.errors.mobile && formik.touched.mobile && (
            <div className="required-message">{formik.errors.mobile}</div>
          )}
          <input
            className="form-input"
            id="password"
            type="password"
            placeholder="Passwort"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="required-message">{formik.errors.password}</div>
          )}
          <input
            className="form-input"
            id="confirmPassword"
            type="password"
            placeholder="Passwort bestätigen"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <div className="required-message">
              {formik.errors.confirmPassword}
            </div>
          )}
          <div className="mt-24">
            <input
              onChange={(e) => {
                setChecked(!checked);
              }}
              className="checkbox"
              type="checkbox"
              id="su-terms"
              name="terms"
              value={checked}
            />
            <label
              className={`grey-text body-text ${!checked && "highlight"} `}
              htmlFor="terms"
            >
              Ich stimme den{" "}
              <a href={Agb} target="_blank">
                Allgemeinen Geschäftsbedingungen
              </a>{" "}
              und der{" "}
              <a href={DatenSchutz} target="_blank">
                Datenschutzerklärung
              </a>{" "}
              zu.
            </label>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="signup-button blue fill-button mt-30"
          >
            Konto erstellen
          </button>
          <div onClick={switchDialog}>
            <p className="body-text grey-text text-center mt-38">
              Du hast schon ein Konto?&nbsp;&nbsp;
              <span className="hyperlink">
                &nbsp;&nbsp;Log dich lieber hier ein
              </span>
            </p>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpForm;
