import React from "react";
import { Link } from "react-router-dom";
import Agb from "../assets/pdf/Agb.pdf";
import DatenSchutz from "../assets/pdf/Datenschutz.pdf";

import "./Footer.scss";

function Footer() {
  const navStyle = {
    textDecoration: "none",
  };

  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer--container-wrapper">
          <div className="logo">
            <Link style={navStyle} to="/">
              <img
                src="/images/foxdeal-logo-white.svg"
                alt="foxdeal logo"
                id="nav-logo"
              ></img>
            </Link>
          </div>
          <div className="first-el">
            <ul>
              <li>
                <Link style={navStyle} to="/products" id="products-link">
                  <li className="bold" style={{ color: "#AEAEAF" }}>
                    Produkte
                  </li>
                </Link>
              </li>
              <li>
                <Link style={navStyle} to="/partners" id="partners-link">
                  <li className="bold" style={{ color: "#AEAEAF" }}>
                    Gönner
                  </li>
                </Link>
              </li>
              <li>
                <a href={Agb} target="_blank">
                  AGB
                </a>
              </li>
              <li>
                <a href={DatenSchutz} target="_blank">
                  Impressum
                </a>
              </li>
            </ul>
          </div>
          <div className="third-el">
            <ul>
              <li> FoxDeal AG</li>
              <li> Bergstrasse 110</li>
              <li>8032 Zürich</li>
              <li>UID CHE-133.275.076</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-rights">
        <p className="caption-text light-grey-text text-center">
          © {new Date().getFullYear()} FoxDeal. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
