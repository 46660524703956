import React, { useState, useEffect } from "react";

const CreditManagement = (props) => {
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [creditsToUse, setCreditsToUse] = useState(0);

  useEffect(() => {
    props.setCreditsToUse(creditsToUse);
    props.setSelectedTasks(selectedTasks);
  }, [creditsToUse]);

  const updateSelectedPartners = (partner) => {
    let newList = [...selectedTasks];
    if (newList.includes(partner.id)) {
      newList = newList.filter((e) => e !== partner.id);
    } else {
      newList.push(partner.id);
    }
    setSelectedTasks(newList);
    let totalCreditsToUse = newList.reduce((acc, e) => {
      let completedTask = props.userCompletedTasks.find((n) => n.id === e);
      if (completedTask) {
        return acc + completedTask.credits;
      }
      return acc;
    }, 0);
    setCreditsToUse(totalCreditsToUse);
  };

  return (
    <div className="billing-info-container gap-top-4">
      <h3 className="sub-header">Verwaltung der Credits:</h3>
      <div className="horizontal-divider mt-18"></div>
      <p className="highlight-text bold mt-35">1. Wähle deinen Partner aus</p>
      <div className="sub-container-grey mt-24">
        <div className="card-grid-3">
          {props.userCompletedTasks.map((task) => {
            return (
              <div
                className="card-1 relative"
                onClick={() => updateSelectedPartners(task)}
              >
                <img src={task.logo} alt="partner"></img>
                <div className="bottom-info">
                  <p className="grey-text caption-text text-center">
                    voraussichtlicher Preis:{" "}
                    <span className="highlight caption-text">
                      {task.credits}
                    </span>
                  </p>
                </div>
                <div
                  className={
                    selectedTasks.includes(task.id)
                      ? "selected-circle"
                      : "unselected-circle"
                  }
                >
                  <img
                    className="center-hv"
                    src={
                      selectedTasks.includes(task.id)
                        ? "/icons/tick-icon.svg"
                        : "/icons/tick-grey-icon.svg"
                    }
                    alt="tick"
                    style={{ width: "50%" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="horizontal-divider mt-35"></div>
      <div className="flex-edge mt-30">
        <p className="small-title">
          Gesamte Credits, die du verwenden willst: {""}
        </p>
        <p className="small-title highlight">CHF{creditsToUse}</p>
      </div>
    </div>
  );
};

export default CreditManagement;
