import React, { forwardRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import dbClient from "../../utils/dbClient";
import { useHistory, useParams } from "react-router-dom";

const Transition = forwardRef(function Transition(props, ref) {
	const { classes, ...other } = props;
	return <Slide direction="up" ref={ref} {...other} />;
});

function ResetPassword(props) {
    let {jwt} = useParams()
	const history = useHistory();
	const [resetPasswordOpen, setResetPasswordOpen] = useState(true);
	const [password, setPassword] = useState("");
	const [passwordConfoirmation, setPasswordConforimation] = useState("");
	const [error, setError] = useState("");
	const passwordResetJwt = null;
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};
	const handleConfoirmationChange = (e) => {
		setPasswordConforimation(e.target.value);
	};
	const handlePasswordReset = async () => {
		if (password !== passwordConfoirmation) {
			setError("Password doesn't Match");
			return;
		}
		try {
			let response = await dbClient.post("/auth/resetPassword", {
				passwordResetJwt:jwt,
				newPassword:password,
			});
			if (response.status === 200) {
				setResetPasswordOpen(false);
				history.push("/");
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleClose = () => {
		setResetPasswordOpen(false);
		history.push("/");
	};
    console.log('printed')
	return (
		<Dialog
			open={resetPasswordOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
		>
			{error ? <div className="error-message text-center">{error}</div> : null}
			<DialogTitle className="text-center">Forgot your password?</DialogTitle>
			<DialogContent>
				<form>
					<div className="mt-24">
						<label className="grey-text body-text" htmlFor="terms">
							Gebe ein neues Passwrot ein	
						</label>
						<input
							value={password}
							className="form-input"
							placeholder="New Password"
							onChange={handlePasswordChange}
						/>
						<input
							value={passwordConfoirmation}
							className="form-input"
							placeholder="Re-enter Password"
							onChange={handleConfoirmationChange}
						/>
					</div>
				</form>
				<div
					className="cta-button blue fill-button mt-30"
					onClick={handlePasswordReset}
				>
					Reset password
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default ResetPassword;
