import React, { useContext, useMemo, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import "./CardForm.scss";
import dbClient from "../../utils/dbClient";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CardForm = () => {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const history = useHistory();
  const { selectedProducts } = useContext(UserContext);

  const cartTotal = selectedProducts.length
    ? selectedProducts.reduce((a, b) => a + (b.price / 2 || 0), 0)
    : 0;

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!payload.error) {
      try {
        const { id } = payload?.paymentMethod;

        const response = await dbClient.post("/payments ", {
          amount: Math.round(cartTotal),
          id,
        });
        if (response.data.success) {
          history.push("/thankyou");
          setSuccess(true);
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <form className="card-form-container" onSubmit={handleSubmit}>
      <label>
        Card details
        <CardElement
          options={options}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={(event) => {
            console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
      </label>
      <button className="form-btn active" type="submit" disabled={!stripe}>
        DEAL
      </button>
    </form>
  );
};

export default CardForm;
