import React from "react";
import { Switch } from "react-router-dom";

import { AppRoutes } from "./routes/AppRoutes";
import Nav from "./components/nav/Nav";
import Footer from "./components/Footer";
import UserContext from "./contexts/UserContext";
import StoreContext from "./contexts/StoreContext";
import InstructionsProvider from "./contexts/InstructionsContext";
import NavigationProvider from "./contexts/NavigationContext";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import ScrollButton from "./components/ScrollButton/ScrollButton";
import { RestrictedRoute } from "./routes/RestrictedRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import { PublicRoute } from "./routes/PublicRoute";
import Page404 from "./pages/Page404";

//stripe imports
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./css_main.scss";

const testKey =
  "pk_test_51JVcU3JNc4xthbFlx7mH2SROGAJsU1Fnd1VPOw6Bbo31HpinLHPTVl5crEHpNY3j9FxEMIhncEpugYXgoJdwIJHO00YeneRV2Y";

//stripe config
const stripePromise = loadStripe(testKey);

const App = () => {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: "{{CLIENT_SECRET}}",
  // };

  return (
    <Elements stripe={stripePromise}>
      <StoreContext>
        <UserContext>
          <AuthContextProvider>
            <NavigationProvider>
              <InstructionsProvider>
                <div className="app">
                  <Nav />
                  <div className="app-content">
                    {/*<BG />*/}
                    <ScrollButton />
                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    top: 0,*/}
                    {/*    left: 0,*/}
                    {/*    height: "100vh",*/}
                    {/*    width: "100vw",*/}
                    {/*    zIndex: 10,*/}
                    {/*    pointerEvents: "none",*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <div className="mobile-switch" id="mobile-switch">*/}
                    {/*    <p>Select Partners</p>*/}
                    {/*    <img*/}
                    {/*      src="/icons/right-arrow-icon.svg"*/}
                    {/*      alt="right arrow"*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="app-container">
                      <Switch>
                        {AppRoutes.map((r, index) => {
                          const { path, ...rest } = r;

                          if (r.restricted) {
                            return (
                              <RestrictedRoute
                                key={index}
                                {...rest}
                                path={`/${path}`}
                              />
                            );
                          }

                          if (r.private) {
                            return (
                              <PrivateRoute
                                key={index}
                                {...rest}
                                path={`/${path}`}
                              />
                            );
                          }

                          return (
                            <PublicRoute
                              key={index}
                              {...rest}
                              path={`/${path}`}
                            />
                          );
                        })}
                        <PublicRoute component={Page404} />
                      </Switch>
                    </div>
                  </div>
                </div>
                <Footer />
              </InstructionsProvider>
            </NavigationProvider>
          </AuthContextProvider>
        </UserContext>
      </StoreContext>
    </Elements>
  );
};

export default App;
