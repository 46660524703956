import { Link } from "react-router-dom";
const OrderNdEnjoy = ({ setStep, handleShowInstructions }) => {
  return (
    <div className="instructions__dialog">
      <div className="instructions__dialog-close">
        <img
          src="/images/remove.svg"
          alt="remove dialog"
          onClick={(e) => {
            setStep(0);
            handleShowInstructions();
          }}
        />
      </div>
      <div>
        <img
          className="instructions__image"
          src="/images/HAND3.png"
          alt="waving hand"
        />
      </div>
      <div>
        <p className="instructions__dialog-step">STEP 3</p>
        <h4 className="instructions__dialog-title">Geniess’ die Belohnung</h4>
      </div>
      <div>
        <p className="instructions__dialog-text">
          So einfach ist’s! Vermittle dich selbst, schliess’ deine Bestellung ab
          und profitiere fett. Dein Produkt wird bequem zu dir nach Hause
          geliefert.
        </p>
      </div>
      <div>
        <Link to="/products">
          <button
            className="instructions__dialog-button instructions__dialog-close-button"
            onClick={(e) => setStep(4)}
          >
            Los geht es!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OrderNdEnjoy;
