import React from "react";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";

export const CloseButton = ({ close }) => {
  const AuthCtx = useAuthContext();
  return (
    <div className="close-graphic" onClick={close || AuthCtx.closeModal}>
      <img src="/images/remove.svg" alt="remove product"></img>
    </div>
  );
};
