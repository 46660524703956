import "./ChooseProduct.scss";

const ChooseProduct = ({ setStep, handleShowInstructions }) => {
  return (
    <div className="instructions__dialog">
      <div className="instructions__dialog-close">
        <img
          src="/images/remove.svg"
          alt="remove dialog"
          onClick={(e) => {
            setStep(0);
            handleShowInstructions();
          }}
        />
      </div>
      <div>
        <img
          className="instructions__image"
          src="/images/HAND1.png"
          alt="waving hand"
        />
      </div>
      <div>
        <p className="instructions__dialog-step">STEP 1</p>
        <h4 className="instructions__dialog-title">Wähl’ dein Produkt</h4>
      </div>
      <div>
        <p className="instructions__dialog-text">
          Du hast deine AirPods verloren und brauchst neue oder willst einfach
          schon lange eine PS5, aber warst bisher zu broke? Such’ dir dein
          Wunschprodukt aus und erhalte durch einen Auftrag bei einem Gönner
          crazy Vergünstigungen und Geschenke!
        </p>
      </div>
      <div>
        <button
          className="instructions__dialog-button"
          onClick={(e) => setStep(2)}
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default ChooseProduct;
