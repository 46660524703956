import React, { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { useHistory } from "react-router-dom";
import dbClient from "../../../utils/dbClient";

export const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [signUpModalOpen, setSignUpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [userError, setUserError] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  const [userDetails, setUser] = useState(userFromLocalStorage);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const history = useHistory();

  const closeVerifyModal = () => {
    setVerifyModalOpen(false);
  };

  const closeForgottenPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = () => {
    if (!userDetails) {
      setIsLoading(false);
      return;
    }

    setIsAuthenticated(true);

    try {
      // get userData from API
    } catch (err) {
      setError(err.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationCode = async (code) => {
    let { name, email, mobile, password } = userDetails;

    try {
      const response = await dbClient.post("/auth/register", {
        name,
        email,
        mobile,
        password,
        code,
      });

      if (response.status === 200) {
        // localStorage.setItem(
        //   "user",
        //   JSON.stringify({ name, email, jwtToken: response.jwtToken, mobile })
        // );
        setVerifyModalOpen(false);
        history.push("/login");
      }
    } catch (err) {
      setError(err);
    }
  };

  const signUp = async (values) => {
    const { name, email, mobile, password } = values;

    // with verification code
    try {
      let response = await dbClient.post("/auth/sendSms", { mobile, email });
      if (response.status === 200) {
        setVerifyModalOpen(true);
        setSignUpModal(false);

        setUser({ name, email, mobile, password });
      }
    } catch (err) {
      setError(err.response && err.response.data.message);
    }
  };

  const closeSignUpModal = () => {
    setSignUpModal(false);
  };

  const openSignUpModal = () => {
    setSignUpModal(true);
  };

  const openForgottenPasswordModal = () => {
    history.push("/");
    setResetPasswordModalOpen(true);
  };

  const login = async (email, password) => {
    try {
      const res = await dbClient.post("/auth/login", {
        email: email,
        password: password,
      });
      setUser(res.data);
      localStorage.setItem("user", JSON.stringify(res.data));

      if (res.data.jwtToken) {
        setIsAuthenticated(true);
        setError("");
        history.push("/");
      }
    } catch (err) {
      if (err) {
        setError(err?.response?.data?.message);
      }
    }
  };

  const logOut = () => {
    localStorage.clear();
    setUser(null);
    setIsAuthenticated(false);
    history.push("/");
  };

  const resetUserError = () => {
    setUserError(false);
  };

  const closeModal = () => {
    history.push("/");
  };

  const context = {
    verifyModalOpen,
    resetPasswordModalOpen,
    isAuthenticated,
    isLoading,
    login,
    error,
    logOut,
    signUp,
    resetUserError,
    userError,
    closeModal,
    setUser,
    userDetails,
    registerError,
    signUpModalOpen,
    closeSignUpModal,
    openSignUpModal,
    openForgottenPasswordModal,
    closeVerifyModal,
    handleVerificationCode,
    closeForgottenPasswordModal,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
