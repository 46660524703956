import React from "react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { StoreContext } from "../../contexts/StoreContext";
const CartSummary = (props) => {
  const { removeProduct } = useContext(UserContext);
  const { products } = useContext(StoreContext);

  const cartTotal = props.cartItems.length
    ? props.cartItems.reduce((a, b) => (a += parseFloat(b.price)), 0)
    : 0;
  const vat = props.cartItems.length ? Math.round((7.7 / 100) * cartTotal) : 0;
  const deliveryCharges = props.cartItems.length ? 7.8 : 0;
  const gTotal = props.cartItems.length
    ? cartTotal + deliveryCharges - props.creditsToUse
    : 0;
  return (
    <div className="cart-summary-container">
      <p className="title">Bestellübersicht</p>
      {props.cartItems.map((item) => {
        return (
          <div className="order-summary-product-view gap-top-2">
            <img src={item.image.src} alt="watch" />
            <div>
              <div
                className="cart-summary-product__info-remove"
                onClick={() => removeProduct(item)}
              >
                <img src="/images/remove.svg" alt="remove product" />
              </div>
              <p className="small-header">
                {
                  products?.find((e) =>
                    e.variants.some((n) => n.id === item.id)
                  )["title"]
                }
              </p>
              <p className="body-text"> {item?.title}</p>
              <div className="horizontal-divider mt-8" />
              <div className="flex-edge mt-4">
                <p className="caption-text">Preis</p>
                <p className="highlight caption-text">
                  <span className="chf">CHF </span>
                  {item.price}
                </p>
              </div>
            </div>
          </div>
        );
      })}
      <div className="horizontal-divider mt-20"></div>
      <div className="cost-summary-container mt-20 body-text">
        {props.cartItems.map((item) => {
          return (
            <div className="flex-edge mt-7">
              <p className="grey-text chf">{item.title}:</p>
              <p className="grey-text body-text">
                <span className="chf">CHF </span>
                {item.price}
              </p>
            </div>
          );
        })}
        <div className="flex-edge mt-16">
          <p className="grey-text chf">Versand:</p>
          <p className="grey-text body-text">
            <span className="chf">CHF </span> {cartTotal > 0 ? "7.80" : 0}
          </p>
        </div>
        <div className="flex-edge mt-16">
          <p className="grey-text chf">Mehrwertsteuer (Inklusive 7.7% MwSt):</p>
          <p className="grey-text body-text">
            <span className="chf">CHF </span> {vat.toFixed(2)}
          </p>
        </div>
        <div className="flex-edge mt-16">
          <p className="grey-text chf">Auf Gönner’s Nacken:</p>
          <p className="edge-row-right body-text">
            -<span className="chf">CHF </span>{" "}
            {cartTotal > 0 ? props.creditsToUse : 0}
          </p>
        </div>
      </div>
      <div className="horizontal-divider mt-20"></div>
      <div className="flex-edge mt-20">
        <p className="summary-text">Total:</p>
        <p className="edge-row-right">
          <span className="chf">CHF </span>
          {gTotal.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default CartSummary;
