import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import NavSignedOut from "./NavSignedOut";
import NavSignedIn from "./NavSignedIn";
import SignUpForm from "../SignUpForm/SignUpForm";
import SignInForm from "../SignInForm/SignInForm";
import VerifyDialog from "./VerifyDialog";
import ThankYouDialog from "./ThankYouDialog";
import ForgotPassword from "./ForgotPassword";
import { gsap } from "gsap";
import dbClient from "../../utils/dbClient";
import { NavigationContext } from "../../contexts/NavigationContext";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./Nav.scss";

const Nav = () => {
  const { signIn, signOut } = useContext(UserContext);

  const navStyle = {
    textDecoration: "none",
  };

  const [signInOpen, setSignInOpen] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [path, setPath] = useState("/");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [yPos, setYPos] = useState(0);
  const [error, setError] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const { navState, handleNavChange } = useContext(NavigationContext);
  const location = useLocation();
  const AuthCtx = useAuthContext();
  const verifyModalOpen = AuthCtx.verifyModalOpen;
  const history = useHistory();
  const user = AuthCtx?.userDetails?.user;

  if (window.innerWidth > 428) {
    window.addEventListener("wheel", () => {
      setYPos(window.scrollY);
    });
  }

  useEffect(() => {
    const tl = gsap.timeline({ onComplete: () => "did it bro" });
    if (yPos === 0) {
      tl.set(".nav-bar-container", {
        border: "initial",
        boxShadow: "initial",
        backdropFilter: "initial",
      });
    } else {
      tl.set(".nav-bar-container", {
        border: "1px solid rgba(255, 255, 255, 0.5)",
        boxShadow: "box-shadow: 0px 14px 140px rgba(0, 0, 0, 0.05)",
        backdropFilter: "backdrop-filter: blur(100px)",
      });
    }
  }, [user]);

  const verifyEmail = async (email) => {
    try {
      AuthCtx.closeForgottenPasswordModal();
      await dbClient.post("/auth/forgotPassword", { email });
    } catch (err) {
      setError(
        err.response ? err.response.data.message : "E-Mail existiert bereits!"
      );
    }
  };
  const resetPassword = () => {
    setSignInOpen(false);
    AuthCtx.openForgottenPasswordModal();
  };

  const register = async (code, name, email, mobile, password) => {
    try {
      const response = await dbClient.post("/auth/register", {
        code: code,
        name: name,
        email: email,
        mobile: mobile,
        password: password,
      });
      if (response.status === 200) {
        try {
          const res = await dbClient.post("/auth/login", {
            email: email,
            password: password,
          });
          if (res.data.user) {
            res.data.user.jwtToken = res.data.jwtToken;
            signIn(res.data.user);
            handleVerifyClose();
          }
        } catch (err) {
          err.response
            ? setError(err.response.data.message)
            : setError("ungültige Zugangsdaten!");
        }
      }
    } catch (err) {
      setError(
        err.response ? err.response.data.message : "E-Mail existiert bereits!"
      );
    }
  };

  const login = async (email, password) => {
    try {
      const response = await dbClient.post("/auth/login", {
        email: email,
        password: password,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignUpOpen = () => {
    AuthCtx.openSignUpModal();
    openMenu();
  };

  const handleSignUpClose = () => {
    AuthCtx.closeSignUpModal();
  };

  const handleSignInClose = () => {
    setSignInOpen(false);
  };

  const handleSignInOpen = () => {
    // setSignInOpen(true);
    history.push("/login");
    openMenu();
  };

  const handleVerifyClose = () => {
    AuthCtx.closeVerifyModal();
    setThankYou(true);
  };
  const handleThankYouClose = () => {
    setThankYou(false);
  };

  const openMenu = () => {
    gsap.timeline().to(".mobile-menu-container", {
      duration: 1,
      left: mobileMenuOpen ? "-100%" : "0%",
    });
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const productStyle = {
    text: {
      color: path === "/products" ? "#1D1D1B" : "#777E90",
    },
    chip: {
      opacity: path === "/products" ? 1 : 0,
    },
  };

  const partnerStyle = {
    text: {
      color: path === "/partners" ? "#1D1D1B" : "#777E90",
    },
    chip: {
      opacity: path === "/partners" ? 1 : 0,
    },
  };

  const dashboardStyle = {
    text: {
      color: path === "/userdashboard" ? "#1D1D1B" : "#777E90",
    },
    chip: {
      opacity: path === "/userdashboard" ? 1 : 0,
    },
  };

  const nameArray = user?.name ? user?.name?.split(" ") : [];

  return (
    <div className="nav-bar-container">
      {window.innerWidth > 576 && (
        <nav>
          <div className="left-nav">
            <Link
              style={navStyle}
              to="/"
              className="logo-link"
              onClick={() => {
                setPath("/");
                handleNavChange({ from: location.pathname, to: "/" });
              }}
            >
              <img src="/icons/new_fox.png" alt="foxdeal logo" id="nav-logo" />
            </Link>
            <div className="divider hide" />
            <ul className="nav-links">
              <div
                className="nav-option"
                onClick={() => {
                  setPath("/products");
                  handleNavChange({
                    from: location.pathname,
                    to: "/products",
                  });
                }}
              >
                <Link style={navStyle} to="/products" id="products-link">
                  <li style={productStyle.text}>Produkte</li>
                </Link>
                <div className="nav-chip" style={productStyle.chip} />
              </div>
              <div className="ml-2" />
              <div
                className="nav-option"
                onClick={() => {
                  setPath("/partners");
                  handleNavChange({
                    from: location.pathname,
                    to: "/partners",
                  });
                }}
              >
                <Link style={navStyle} to="/partners" id="partners-link">
                  <li style={partnerStyle.text}>Gönner</li>
                </Link>
                <div className="nav-chip" style={partnerStyle.chip} />
              </div>
              <div className="ml-2"></div>
              <div
                className="nav-option"
                onClick={() => {
                  setPath("/userdashboard");
                  handleNavChange({
                    from: location.pathname,
                    to: "/userdashboard",
                  });
                }}
              >
                <Link style={navStyle} to="/userdashboard" id="dashboard-link">
                  <li style={dashboardStyle.text}>Dashboard</li>
                </Link>
                <div className="nav-chip" style={dashboardStyle.chip} />
              </div>
            </ul>
          </div>
          {AuthCtx.isAuthenticated ? (
            <NavSignedIn />
          ) : (
            <NavSignedOut
              handleSignUpOpen={handleSignUpOpen}
              handleSignInOpen={handleSignInOpen}
            />
          )}
        </nav>
      )}
      {window.innerWidth <= 576 && (
        <div className="mobile-nav">
          <img src="/icons/hamburger-icon.svg" alt="menu" onClick={openMenu} />
          <Link style={navStyle} to="/" className="logo-link">
            <img src="/icons/new_fox.png" alt="foxdeal logo" id="nav-logo" />
          </Link>
          <Link style={navStyle} to="/cart" className="cart-link">
            <img src="/icons/cart-icon.svg" alt="cart" />
          </Link>
        </div>
      )}
      <div className="mobile-menu-container">
        <div className="mobile-nav">
          <img src="/icons/hamburger-icon.svg" alt="close" onClick={openMenu} />
          <Link
            style={navStyle}
            to="/"
            className="logo-link"
            onClick={openMenu}
          >
            <img src="/icons/new_fox.png" alt="foxdeal logo" id="nav-logo" />
          </Link>
        </div>
        <div className="horizontal-divider mt-16" />
        <div className="mobile-menu-content">
          <div>
            <Link style={navStyle} to="/products" onClick={openMenu}>
              <p className="title mt-85">Produkte</p>
            </Link>
            <Link style={navStyle} to="/partners" onClick={openMenu}>
              <p className="title mt-50">Gönner</p>
            </Link>
            <Link style={navStyle} to="/userdashboard" onClick={openMenu}>
              <p className="title mt-50">Dashboard</p>
            </Link>
          </div>
          {!AuthCtx.isAuthenticated && (
            <div className="flex-edge">
              <div className="outline-button bw-140" onClick={handleSignInOpen}>
                Sign in
              </div>
              <div
                className="cta-button blue bw-140"
                onClick={handleSignUpOpen}
              >
                Sign up
              </div>
            </div>
          )}
          {AuthCtx.isAuthenticated && (
            <div className="sub-container-flat">
              <div className="outline-button fill-button flex-edge-padded justify-center">
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginLeft: "-10px",
                  }}
                >
                  <div className="user-icon">
                    <p className="center-hv white-text">
                      {nameArray[0].charAt(0)}
                      {nameArray[1] && nameArray[1].charAt(0)}
                    </p>
                  </div>
                  <p className="body-text bold gap-left-12">{user.name}</p>
                </div>
              </div>
              <div className="horizontal-divider mt-30" />
              <Link style={navStyle} to="/userdashboard">
                <div className="inline mt-30">
                  <img src="/icons/user-grey-icon.svg" alt="user" />
                  <p
                    className="grey-text small-header gap-left-20"
                    onClick={openMenu}
                  >
                    User dashboard
                  </p>
                </div>
              </Link>
              <div className="inline mt-38" onClick={signOut}>
                <img src="/icons/exit-icon.svg" alt="exit" />
                <p
                  className="grey-text small-header gap-left-20"
                  onClick={AuthCtx.logOut}
                  onClick={() => {
                    AuthCtx.logOut();
                    openMenu();
                  }}
                >
                  Sign out
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <SignInForm
        open={signInOpen}
        switchDialog={handleSignUpClose}
        signIn={signIn}
        handleClose={handleSignInClose}
        login={login}
        resetPassword={resetPassword}
      />
      <SignUpForm
        open={AuthCtx.signUpModalOpen}
        switchDialog={handleSignInOpen}
        signIn={signIn}
        handleClose={handleSignUpClose}
        verifyMobileNumber={handleSignInClose}
        register={register}
        error={error}
      />
      <VerifyDialog
        open={verifyModalOpen}
        mobile={userInfo.mobile}
        handleClose={handleVerifyClose}
      />
      <ThankYouDialog
        open={thankYou}
        handleThankYouClose={handleThankYouClose}
      />
      <ForgotPassword
        open={AuthCtx.resetPasswordModalOpen}
        verifyEmail={verifyEmail}
        error={error}
        handleClose={AuthCtx.closeForgottenPasswordModal}
      />
    </div>
  );
};

export default Nav;
