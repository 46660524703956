import React from "react";
import {Link} from "react-router-dom";

const HomeHero = () => {
    const scrollToProducts = () => {
        document
            .getElementsByClassName("home-products")[0]
            .scrollIntoView({behavior: "smooth"});
    };

    return (
        <div>
            <div className="hero">
                <div className="hero-text-column hero-title">
                    <h1>
                        Wild, young and günstig –{" "}
                        <span className="highlight header">Fox</span>Deal!
                    </h1>
                    <p className="mt-16 grey-text">
                        Stell dir vor, du kannst selbst entscheiden, wie viel du für dein
                        Lieblingsprodukt liegen lässt. FoxDeal verbindet dich mit Gönnern,
                        mit denen du crazy Deals abschliessen kannst. Gratis Iphone?
                    </p>
                    <Link
                        style={{textDecoration: "none"}}
                        to="/products"
                        id="products-link"
                    >
                        <div className="cta-button blue body-text bw-170 mt-37">
                            Gönn Dir!
                        </div>
                    </Link>
                </div>
                <div className="hero-mascot-column">
                    <video
                        src="/icons/fox-animation-cropped.webm"
                        autoPlay
                        muted
                        loop
                        width="100%"
                    ></video>
                </div>
            </div>
            <div className="scroll-down mt-128">
                <img src="/icons/down.svg" alt="down arrow"></img>
                <p className="body-text bold" onClick={scrollToProducts}>
                    Scroll runter, um die Produkte zu bestaunen
                </p>
            </div>
        </div>
    );
};

export default HomeHero;
