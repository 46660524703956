import { useState } from "react";
import { useLocation } from "react-router-dom";
const ProgressBar = (props) => {
  const areSomeProductsSelected = props.selectedProducts.length > 0;
  const areSomePartnersSelected = props.selectedPartners.length > 0;
  const [expand, setExpand] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={`progress-bar${
          areSomeProductsSelected || areSomePartnersSelected
            ? " progress-bar_with-expand"
            : ""
        }`}
      >
        <>
          <div className="progress-bar_item progress-bar_item-with-line">
            <div className="progress-bar_item-title">
              <p
                className={`progress-bar_number ${
                  pathname === "/products" && "progress-bar_item-current"
                } ${
                  areSomeProductsSelected &&
                  pathname !== "/products" &&
                  "progress-bar_item-previous"
                }`}
              >
                1
              </p>
              <p
                className={`${
                  pathname === "/products" && "progress-bar_item-current-color"
                } ${
                  areSomeProductsSelected &&
                  pathname !== "/products" &&
                  "progress-bar_item-previous-color"
                }`}
              >
                Produkt auswählen
              </p>
              <div className="progress-bar_line" />
            </div>
            {expand &&
              props.selectedProducts.map((product) => {
                return (
                  <div className="progress-bar_product" key={product.id}>
                    <img
                      className="progress-bar_product-image"
                      src={product.image.src}
                      alt={product.title}
                    />
                    <p className="progress-bar_product-title">
                      {product.title}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="progress-bar_item progress-bar_item-with-line">
            <div className="progress-bar_item-title">
              <p
                className={`progress-bar_number ${
                  pathname === "/partners" && "progress-bar_item-current"
                } ${
                  areSomePartnersSelected &&
                  pathname !== "/partners" &&
                  "progress-bar_item-previous"
                }`}
              >
                2
              </p>
              <p
                className={`${
                  pathname === "/partners" && "progress-bar_item-current-color"
                } ${
                  areSomePartnersSelected &&
                  pathname !== "/partners" &&
                  "progress-bar_item-previous-color"
                }`}
              >
                Gönner auswählen
              </p>
              <div className="progress-bar_line" />
            </div>
            {expand && (
              <div className="progress-bar_partner">
                {props.selectedPartners.map((partner) => {
                  return (
                    <div className="progress-bar_partner-item" key={partner.id}>
                      <img
                        className="progress-bar_product-image"
                        src={partner.logo}
                        alt={partner.title}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
        <div className="progress-bar_item">
          <div className="progress-bar_item-title">
            <p
              className={`progress-bar_number ${
                pathname === "/userdashboard" && "progress-bar_item-current"
              } `}
            >
              3
            </p>
            <p
              className={`${
                pathname === "/userdashboard" &&
                "progress-bar_item-current-color"
              } 
              
              
              `}
            >
              Bestellen
            </p>
          </div>
        </div>
        {(areSomeProductsSelected || areSomePartnersSelected) && (
          <div
            className="progress-bar_expand"
            onClick={() => setExpand(!expand)}
          >
            <p>
              {expand ? "Weniger" : "Weitere"} Informationen über die
              ausgewählten Artikel anzeigen
              {expand ? (
                <img src="/icons/show_less.svg" alt="Show less" />
              ) : (
                <img src="/icons/show_more.svg" alt="Show more" />
              )}
            </p>
          </div>
        )}
      </div>
      <div className="progress-bar_divider" />
    </>
  );
};

export default ProgressBar;
