import "./HowDoesItWork.scss";

const HowDoesItWork = ({ setStep, handleShowInstructions }) => {
  return (
    <div className="instructions__dialog">
      <div className="instructions__dialog-close">
        <img
          src="/images/remove.svg"
          alt="remove dialog"
          onClick={(e) => {
            setStep(0);
            handleShowInstructions();
          }}
        />
      </div>
      <img
        className="instructions__image"
        src="/images/Hand_0.png"
        alt="waving hand"
      />
      <div>
        <p className="instructions__dialog-step">Anleitung</p>
        <h4 className="instructions__dialog-title">Wie läuft’s?</h4>
      </div>
      <div>
        <p className="instructions__dialog-text">
          Lass’ dir kurz und knackig in drei einfachen Schritten erklären, wie
          alles funktioniert und wie du beim Bestellen von einem Produkt oder
          beim Abschliessen eines Auftrags richtig absahnen kannst.
        </p>
      </div>
      <div>
        <button
          className="instructions__dialog-button"
          onClick={(e) => setStep(1)}
        >
          Erster Schritt ansehen
        </button>
      </div>
    </div>
  );
};

export default HowDoesItWork;
