import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavigationContext } from "../../contexts/NavigationContext";
import { UserContext } from "../../contexts/UserContext";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

function NavSignedIn() {
  const navStyle = {
    textDecoration: "none",
  };

  const { selectedProducts } = useContext(UserContext);
  const { navState, handleNavChange } = useContext(NavigationContext);
  const location = useLocation();
  const AuthCtx = useAuthContext();
  const { user } = AuthCtx.userDetails;

  const [isTabletMenuOpen, setTabletMenuOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const t = e.target;
      if (
        !t.closest(".tablet-user-dropdown") &&
        isTabletMenuOpen &&
        !t.className.includes("outline-button")
      ) {
        setTabletMenuOpen(false);
      }
    });
  }, [isTabletMenuOpen]);

  const cartStyle = selectedProducts.length
    ? "cart-amount"
    : "cart-amount mask";
  const nameArray = user?.name?.split(" ");

  const TabletDropdown = () => {
    return (
      <div className="tablet-user-dropdown">
        {window.window.innerWidth <= 1200 && (
          <div
            style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}
          >
            <div className="user-icon">
              <p className="center-hv white-text">
                {nameArray[0]?.charAt(0)}{" "}
                {nameArray[1] && nameArray[1]?.charAt(0)}
              </p>
            </div>
            <p className="body-text bold gap-left-12">{user.name}</p>
          </div>
        )}
        <Link style={navStyle} to="/userdashboard">
          <div
            className="mt-20"
            style={{ position: "relative", display: "flex", gap: "1rem" }}
            onClick={() => setTabletMenuOpen(!isTabletMenuOpen)}
          >
            <img src="/icons/user-grey.svg" alt="user" />
            <p>User Dashboard</p>
          </div>
        </Link>
        <div className="horizontal-divider mt-22" />
        <div
          className="mt-22"
          style={{
            position: "relative",
            display: "flex",
            gap: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            AuthCtx.logOut();
            setTabletMenuOpen(!isTabletMenuOpen);
          }}
        >
          <img src="/icons/door.svg" alt="door" />
          <p>Sign Out </p>
        </div>
      </div>
    );
  };

  return (
    <div className="right-nav">
      <Link style={navStyle} to="/cart">
        <div
          className="cart"
          onClick={() =>
            handleNavChange({ from: location.pathname, to: "/cart" })
          }
        >
          <div className={cartStyle}>
            <p className="center-hv cart-text white-text">
              {selectedProducts.length}
            </p>
          </div>
          <img src="/icons/cart-icon.svg" alt="cart icon" />
        </div>
      </Link>
      <div className="vertical-divider" />
      <div className="sign-in-buttons">
        <div className="outline-button flex-edge-padded bw-184">
          <p className="body-text bold">
            Dein Score: <span className="highlight">{user.credits}</span>
          </p>
          <img src="/icons/info-icon.svg" alt="info" />
        </div>
        <div
          className="outline-button bw-66"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-evenly",
            width: "auto",
            padding: "10px",
            zIndex: "55",
          }}
          onClick={(e) => {
            setTabletMenuOpen(!isTabletMenuOpen);
          }}
        >
          <div
            style={{
              pointerEvents: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            {window.innerWidth >= 1200 && (
              <div className="user-icon">
                <p className="center-hv-new white-text">
                  {nameArray[0]?.charAt(0)}{" "}
                  {nameArray[1] && nameArray[1]?.charAt(0)}
                </p>
              </div>
            )}
            {window.innerWidth >= 1200 && (
              <p className="body-text bold gap-left-12">{user.name}</p>
            )}
            {window.innerWidth <= 1200 && (
              <img src="/icons/user-icon.svg" alt="down chevron" />
            )}
            <img
              style={{ marginLeft: "10px" }}
              src="/icons/down-chevron-icon.svg"
              alt="down chevron"
            />
          </div>
        </div>
      </div>
      {isTabletMenuOpen && <TabletDropdown />}
    </div>
  );
}

export default NavSignedIn;
