import React, { useContext, createContext } from "react";

const AuthContextValues = {
  isAuthenticated: false,
  signUpModalOpen: false,
  resetPasswordModalOpen: false,
  isLoading: false,
  userDetails: undefined,
  error: undefined,
  login: () => {},
  checkAuthentication: () => {},
  handleVerificationCode: () => {},
  handleFromCheckout: () => {},
  closeSignUpModal: () => {},
  openSignUpModal: () => {},
  openForgottenPasswordModal: () => {},
  closeVerifyModal: () => {},
  closeForgottenPasswordModal: () => {},
  resetUserError: () => {},
  logOut: () => {},
  signUp: () => {},
  closeModal: () => {},
  userError: undefined,
  registerError: undefined,
  verifyModalOpen: false,
};

export const AuthContext = createContext(AuthContextValues);
export const useAuthContext = () => useContext(AuthContext);
