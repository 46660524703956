import SignInForm from "../components/SignInForm/SignInForm";
import Products from "../pages/products/Products";
import Partners from "../pages/partners/Partners";
import Cart from "../pages/cart/Cart";
import UserDashboard from "../pages/userdashboard/UserDashboard";
import ThankYou from "../pages/thankyou/ThankYou";
import ResetPassword from "../pages/reset-password/ResetPassword";
import Home from "../pages/home/Home";
import SingleProduct from "../pages/products/SingleProduct/SingleProduct";

export const AppRoutes = [
  //public routes
  {
    path: "/",
    exact: true,
    component: Home,
    name: "Test",
  },
  {
    path: "login",
    exact: true,
    component: SignInForm,
    name: "Sign In",
  },
  {
    path: "products",
    component: Products,
    name: "Products",
  },
  {
    path: "product/:id?",
    component: SingleProduct,
    name: "Single Product",
  },

  {
    path: "partners",
    exact: true,
    component: Partners,
    name: "Partners",
  },
  {
    path: "cart",
    exact: true,
    component: Cart,
    name: "Cart ",
  },

  {
    path: "thankyou",
    exact: true,
    component: ThankYou,
    name: "Thank You",
  },
  {
    path: "passwordreset/:jwt",
    exact: true,
    component: ResetPassword,
    name: "Reset Password",
  },

  //private routes
  {
    path: "userdashboard",
    exact: true,
    component: UserDashboard,
    name: "User Dashboard",
    private: true,
  },
];
