import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../../contexts/StoreContext";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import ProgressBar from "../products/ProgressBar";
import PartnerInfoDialog from "../../components/partners/PartnerInfoDialog";

import "./Partners.scss";

const Partners = (props) => {
  const { fetchAllPartners, partners } = useContext(StoreContext);
  const { user, selectedProducts, selectedPartners, updatePartners } =
    useContext(UserContext);
  const [hoveredPartner, setHoveredPartner] = useState("");
  const [showPartnerInfo, setShowPartnerInfo] = useState(false);
  const [partnerToShow, setPartnerToShow] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAllPartners(user.id);
  }, [user]);

  const handlePartnerHover = (partner) => {
    setHoveredPartner(partner.id);
  };

  const handlePartnerLeave = () => {
    setHoveredPartner("");
  };
  const handleClose = () => {
    setShowPartnerInfo(false);
  };
  const handleShowPartner = (partner) => {
    setPartnerToShow(partner);
    setShowPartnerInfo(true);
  };

  return (
    <div className="partners-page-container footer-margin">
      <ProgressBar
        selectedProducts={selectedProducts}
        selectedPartners={selectedPartners}
        location={props.location}
      />
      <div className="partners-content flex-edge flo">
        <div>
          <p className="partner_credits">
            Credits, die du Max erhalten Kannst:{" "}
            <span className="partner_credits-red">
              {selectedPartners.reduce((acc, e) => acc + e.maxCredits, 0)}
            </span>
          </p>
        </div>

        <div
          style={{
            textDecoration: "none",
            pointerEvents: selectedPartners.length > 0 ? "all" : "none",
          }}
          onClick={() => history.push("/userdashboard")}
          id="ud-link"
        >
          <div
            className={`deal-btn cta-button  bw-229 ${
              selectedPartners.length > 0 ? "red" : "button-disabled"
            }`}
          >
            <div className="inline justify-center">
              <span
                className="white-text body-text bold"
                style={{ letterSpacing: "initial" }}
              >
                Schnapp’ dir deinen Deal
              </span>
              <img
                className="gap-left-05 white-text"
                src="/icons/right-arrow-icon.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="horizontal-divider mt-30" />
      <div className="partner-list-grid mt-30">
        {partners.map((partner) => {
          return (
            <div
              key={partner.name}
              className="partners-card-container partner-wrapper"
              onMouseMove={() => handlePartnerHover(partner)}
              onMouseLeave={() => handlePartnerLeave()}
            >
              {selectedPartners.find((e) => e.name === partner.name) &&
              partner.id !== hoveredPartner ? (
                <div className="select_mark">
                  <img src="/images/select_mark.svg" alt="select mark"></img>
                </div>
              ) : null}
              {partner.id === hoveredPartner ? (
                <div className="partner">
                  <div className="partner_title">
                    <p className="bold text-center partner-fonts">
                      {partner.name}
                    </p>
                    <img
                      className="partner_info"
                      src="/icons/info-icon.svg"
                      alt="info icon"
                      onClick={() => handleShowPartner(partner)}
                    ></img>
                  </div>
                  <div className="partner_points">
                    <p className="partner_points-number">
                      {partner.maxCredits}
                    </p>
                    <p className="partner_points-text">
                      Credits die du Max erhälst
                    </p>
                  </div>

                  {selectedPartners.find((e) => e.name === partner.name) ? (
                    <div
                      className="soft-green-button fill-button skinny-button mt-13 partner-buttons"
                      onClick={() => updatePartners(partner)}
                    >
                      Partner Selected
                    </div>
                  ) : (
                       <div
                      className="soft-blue-button fill-button skinny-button mt-13 partner-buttons"
                      onClick={() => updatePartners(partner)}
                    >
                      Gönner auswählen
                    </div>
                  )}
                  <img
                    className="partner-logo"
                    src={partner.logo}
                    alt="logo"
                    style={{ opacity: 0, display: "none" }}
                  ></img>
                </div>
              ) : (
                <div>
                  <img
                    className="partner-logo"
                    src={partner.logo}
                    alt="logo"
                  ></img>
                </div>
              )}
            </div>
          );
        })}
        <div
            key={'salt'}
            className="partners-card-container"
        >
              <div className={'salt-wrapper'}>
                <img
                    src="/images/salt-logo.svg"
                    alt="remove dialog"
                ></img>
              </div>
          <div className={'salt-image-wrapper'}>
          <img
              style={{height:'inherit'}}
              src="/images/coming-soon-removebg-preview.png"
              alt="remove dialog"
          ></img>
          </div>
        </div>

      </div>
      <PartnerInfoDialog
        open={showPartnerInfo}
        handleClose={handleClose}
        partner={partnerToShow}
      />
    </div>
  );
};

export default Partners;
