import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollButton = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [showScrollUpButton, setShowScrollUpButton] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400) {
      setShowScrollUpButton(true);
    } else if (scrolled <= 400) {
      setShowScrollUpButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  if (!showScrollUpButton) return null;

  return (
    <div className="scroll-up" onClick={scrollToTop}>
      <img src="/icons/up.svg" alt="up arrow" />
    </div>
  );
};

export default ScrollButton;
