import { useFormik } from "formik";
import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Enter your name!"),
  email: Yup.string()
    .required("Geben sie ihre E-Mail Adresse ein!")
    .email("Geben Sie eine gültige E-Mail-Adresse ein!"),
  mobile: Yup.string().required("Enter your phone number!"),
  password: Yup.string().required("Bitte geben Sie Ihr Passwort ein!"),
  // .matches(
  //   /^(?=.*\d)(?=.*[a-zA-Z_\@\%\&\#]).{6,14}$/,
  //   "Password must be between 6 and 14 characters in length, with at least one uppercase letter, lowercase letter and one number. Allowed characters are letters, numbers, and @, _, %, &, #."
  // ),
  // .max(50, "Password is too Long!"),
  confirmPassword: Yup.string()
    .required("Bitte bestätigen Sie das Passwort!")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwort und Passwort bestätigen stimmen nicht überein!"
      ),
    }),
});

export const useSignUpFormik = (opts) => {
  return useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      ...opts?.initialValues,
      SignUpSchema,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SignUpSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
