import "./ChoosePartner.scss";

const ChoosePartner = ({ setStep, handleShowInstructions }) => {
  return (
    <div className="instructions__dialog">
      <div className="instructions__dialog-close">
        <img
          src="/images/remove.svg"
          alt="remove dialog"
          onClick={(e) => {
            setStep(0);
            handleShowInstructions();
          }}
        />
      </div>
      <div>
        <img
          className="instructions__image"
          src="/images/HAND2.png"
          alt="waving hand"
        />
      </div>
      <div>
        <p className="instructions__dialog-step">STEP 2</p>
        <h4 className="instructions__dialog-title">Wähl’ deinen Gönner</h4>
      </div>
      <div>
        <p className="instructions__dialog-text">
          Ob du ‘ne neue Kreditkarte brauchst, dir die Franchise bei deiner
          aktuellen Krankenkasse zu hoch ist oder du unzufrieden mit deinem
          Handy Abo bist - Finde den richtigen Gönner für dich und gönn’ dir
          dafür selber was.
        </p>
      </div>
      <div>
        <button
          className="instructions__dialog-button"
          onClick={(e) => setStep(3)}
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default ChoosePartner;
