import React, { forwardRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { CloseButton } from "../CloseButton";

import "./SignInForm.scss";

const Transition = forwardRef(function Transition(props, ref) {
  const { classes, ...other } = props;
  return <Slide direction="up" ref={ref} {...other} />;
});

const SignInForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { pathname } = useLocation();
  const history = useHistory();
  const AuthCtx = useAuthContext();
  const error = AuthCtx.error;

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const switchDialog = () => {
    // props.handleClose();

    props.switchDialog();
  };

  const submitForm = async () => {
    AuthCtx.login(email, password);
  };

  return (
    <Dialog
      open={pathname === "/login"}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => history.push("/")}
      className="signin-page-container"
    >
      <div className="error-message text-center"> {error ? error : ""} </div>
      <CloseButton />
      <DialogTitle className="text-center">{"Sign in"}</DialogTitle>
      <DialogContent>
        <form>
          <input
            className="form-input"
            id="su-email"
            placeholder="Email"
            onChange={handleEmailInput}
          />
          <input
            type="password"
            className="form-input mt-10"
            id="su-password"
            placeholder="Passwort"
            onChange={handlePasswordInput}
          />
        </form>
        <div className="cta-button blue fill-button mt-30" onClick={submitForm}>
          Sign in
        </div>
        <div className="flex-edge mt-38">
          <div onClick={switchDialog}>
            <p className="body-text grey-text">
              Noch kein Glückspilz?&nbsp;
              <span className="hyperlink">&nbsp;Registrier’ dich jetzt</span>
            </p>
          </div>
          <p
            className="body-text underline grey-text"
            onClick={AuthCtx.openForgottenPasswordModal}
          >
            Passwort vergessen? Hier lang
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignInForm;
