import React, { useState, useContext, useEffect } from "react";
import ProductsGrid from "./ProductsGrid/ProductsGrid";
import { Link, useHistory, useParams } from "react-router-dom";
import ProductFilterMenu from "./ProductFilterMenu";
import { UserContext } from "../../contexts/UserContext";
import { StoreContext } from "../../contexts/StoreContext";
import { gsap } from "gsap";
import Instructions from "../instructions/Instructions/Instructions";
import ProgressBar from "./ProgressBar";
import { InstructionsContext } from "../../contexts/InstructionsContext";
import { NavigationContext } from "../../contexts/NavigationContext";
import SingleProduct from "./SingleProduct/SingleProduct";

const Products = (props) => {
  const [expandedProduct, setExpandedProduct] = useState({});
  const [dealLinkStatus, setDealLinkStatus] = useState("disabled");
  const [isBackToProductsHovered, setIsBackToProductsHovered] = useState(false);
  const {
    user,
    activeProduct,
    setActiveProduct,
    selectedProducts,
    selectedPartners,
  } = useContext(UserContext);
  const { products, readOnlyProducts } = useContext(StoreContext);
  const { fetchAllPartners } = useContext(StoreContext);
  const { showInstructions, handleShowInstructions } =
    useContext(InstructionsContext);
  let productsActive = true;
  const history = useHistory();
  const params = useParams();

  const handleBackToProductsEnter = () => {
    setIsBackToProductsHovered(true);
  };
  const handleBackToProductsLeave = () => {
    setIsBackToProductsHovered(false);
  };

  const getDealLinkStatus = () => {
    if (!user.name) {
      setDealLinkStatus("sign-in");
    } else if (selectedProducts.length) {
      setDealLinkStatus("active");
    } else {
      setDealLinkStatus("disabled");
    }
  };

  useEffect(() => {
    fetchAllPartners(user.id);
    const product = Object.assign({}, activeProduct);
    setExpandedProduct(product);
    getDealLinkStatus();
  }, [activeProduct, setActiveProduct, dealLinkStatus, user, selectedProducts]);

  useEffect(() => {
    handleShowInstructions();
  }, []);

  const switchGrid = () => {
    if (productsActive) {
      if (document.getElementById("active-product-panel")) {
        gsap.timeline().set(".active-product-panel", { display: "none" });
      } else {
        gsap.timeline().set(".nested-products-grid", { display: "none" });
      }
      gsap.timeline().set("#view-all", { display: "none" });
      gsap.timeline().set(".nested-partners-container", { display: "initial" });
    } else {
      if (document.getElementById("active-product-panel")) {
        gsap.timeline().set(".active-product-panel", { display: "block" });
      } else {
        gsap.timeline().set(".nested-products-grid", { display: "grid" });
      }
      gsap.timeline().set("#view-all", { display: "initial" });
      gsap.timeline().set(".nested-partners-container", { display: "none" });
    }
    productsActive = !productsActive;
  };

  const mobileSwitch = document.getElementById("mobile-switch");
  if (mobileSwitch) {
    mobileSwitch.addEventListener("click", switchGrid);
  }

  const backToProductsSrc = isBackToProductsHovered
    ? "/icons/left-arrow-active-icon.svg"
    : "/icons/left-arrow-icon.svg";

  return (
    <div className="footer-margin">
      {showInstructions ? (
        <Instructions handleShowInstructions={handleShowInstructions} />
      ) : null}
      <ProgressBar
        selectedProducts={selectedProducts}
        selectedPartners={selectedPartners}
        location={props.location}
      />
      <div className="flex-edge">
        {!expandedProduct.title && <ProductFilterMenu />}
        {expandedProduct.title && (
          <div
            className="outline-button bw-263"
            onClick={() => {
              history.push("/products");
            }}
            onMouseEnter={handleBackToProductsEnter}
            onMouseLeave={handleBackToProductsLeave}
          >
            <div className="inline justify-center">
              <img src={backToProductsSrc} alt="arrow" />
              <p
                className="gap-left-1 body-text bold"
                style={{ letterSpacing: "initial" }}
              >
                Zurück zu allen Produkte
              </p>
            </div>
          </div>
        )}
        <div
          style={{
            textDecoration: "none",
            pointerEvents: selectedProducts.length > 0 ? "all" : "none",
          }}
          onClick={() => history.push("/partners")}
          id="ud-link"
        >
          <div
            className={`deal-btn cta-button  bw-229 ${
              selectedProducts.length > 0 ? "red" : "button-disabled"
            }`}
          >
            <div className="inline justify-center">
              <p
                className="white-text body-text bold"
                style={{ letterSpacing: "initial" }}
              >
                Schnapp’ dir deinen Deal
              </p>
              <img
                className="gap-left-05 white-text"
                src="/icons/right-arrow-icon.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="products-partners-grid mt-38">
        <div>
          {!params.id ? (
            <ProductsGrid
              setActiveProduct={setExpandedProduct}
              expandedProduct={expandedProduct}
              key={expandedProduct.title}
            />
          ) : (
            <SingleProduct />
          )}

          {!expandedProduct.title && (
            <div className="flex-edge flo mt-30" id="view-all">
              <p className="grey-text body-text">
                Du siehst <strong>{products?.length}</strong> alle{" "}
                <strong>{readOnlyProducts?.length}</strong> Produkte
              </p>
              <Link style={{ textDecoration: "none" }} to="/products">
                <div className="cta-button blue bw-205">
                  Ich will alles sehen
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
