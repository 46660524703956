import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const PartnerTotal = (props) => {
	const { selectedPartners } = useContext(UserContext);
	return (
		<div className="userdashboard_partner-total">
			<p className="userdashboard_partner-total-title">
				Total credits you will get:{" "}
				<span className="userdashboard_partner-total-number">
					{selectedPartners.reduce((acc,e) => acc + e.task.credits,0)}
				</span>
			</p>
		</div>
	);
};
export default PartnerTotal;
