import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { StoreContext } from "../../contexts/StoreContext";
import PersonalInformation from "./PersonalInformation";
import UserDashboardProducts from "./UserDashboardProducts";
import UserDashboardPartners from "./UserDashboardPartners";
import { Link } from "react-router-dom";
import ProgressBar from "../products/ProgressBar";
import PartnerTotal from "../../components/partners/PartnerTotal";

const UserDashboard = (props) => {
  const {
    user,
    selectedProducts,
    selectedPartners,
    completedTask,
    userCompletedTasks,
  } = useContext(UserContext);
  const [partnersComplete, setPartnersComplete] = useState(false);

  const buttonSize = window.innerWidth > 576 ? "bw-194" : "fill-button";
  const bottomMargin = window.innerWidth > 576 ? "mt-50" : "mt-30";

  return (
    <>
      <ProgressBar
        selectedProducts={selectedProducts}
        selectedPartners={selectedPartners}
        location={props.location}
      />
      <div className="footer-margin">
        {selectedPartners.length > 0 ? <PartnerTotal /> : null}
        <div
          className={`${
            userCompletedTasks.length > 0
              ? `ud-content-grid mt-30`
              : `ud-content mt-30`
          }`}
        >
          {userCompletedTasks.length > 0 ? (
            <div className="userdashboard_total">
              <div className="userdashboard_total-overlay">
                <p className="userdashboard_total-number">
                  {userCompletedTasks.reduce((acc, e) => acc + e.credits, 0)}
                </p>
                <p className="userdashboard_total-text">Your credits</p>
              </div>
            </div>
          ) : null}
          <div>
            <PersonalInformation user={user} />
            <UserDashboardProducts selectedProducts={selectedProducts} />

            <UserDashboardPartners
              partnersComplete={partnersComplete}
              setPartnersComplete={setPartnersComplete}
              selectedPartners={selectedPartners}
              completedTask={completedTask}
            />
            <div className={"horizontal-divider " + bottomMargin}></div>
            <div className={"flex-edge flo " + bottomMargin}>
              <div>
                <p className="sub-header">
                  Gesamtbetrag: <span className="chf">CHF </span>
                  {selectedPartners.reduce((acc, e) => acc + e.task.credits, 0)}
                </p>
              </div>
              <div className="completed-chip">
                <Link
                  style={{ textDecoration: "none" }}
                  to="/cart"
                  id="cart-link"
                >
                  <div
                    className={
                      selectedProducts.length && partnersComplete
                        ? "cta-button red " + buttonSize
                        : "button-disabled " + buttonSize
                    }
                    style={{ marginTop: window.innerWidth > 576 ? 0 : "20px" }}
                  >
                    Deal!
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
